<template>
  <app-page-layout :title="$t('hr.document.add_document')" @close="leavePage">
    <template #content>
      <v-card :disabled="loading" flat>
        <validation-observer ref="form">
          <v-flex xs12>
            <validation-provider :name="$t('hr.document.name')" rules="required" v-slot="{ errors }">
              <v-text-field v-model="document.name" :label="$t('hr.document.name')" :error-messages="errors" />
            </validation-provider>
          </v-flex>
          <v-flex xs12>
            <validation-provider :name="$t('hr.document.type')" rules="required" v-slot="{ errors }">
              <document-type-picker v-model="document.type" :error-messages="errors" />
            </validation-provider>
          </v-flex>
          <v-flex xs12>
            <validation-provider :name="$t('global.file')" rules="required" v-slot="{ errors }">
              <file-upload-picker
                on-create
                storageType="DOCUMENT"
                @send="cb => (onFileSend = cb)"
                v-model="document.url"
                :error-messages="errors"
              />
            </validation-provider>
          </v-flex>
          <v-flex xs12>
            <v-switch
              color="primary"
              v-model="document.mobileAccessible"
              :label="
                document.mobileAccessible
                  ? $t('hr.document.mobile_accessible_enable')
                  : $t('hr.document.mobile_accessible_disable')
              "
            ></v-switch>
          </v-flex>
        </validation-observer>
      </v-card>
    </template>
    <template #footer>
      <app-page-footer>
        <template #right>
          <v-btn text @click="validateAndSave" :loading="loading">{{ $t("buttons.save") }}</v-btn>
        </template>
      </app-page-footer>
    </template>
  </app-page-layout>
</template>

<script>
  import Document from "../model/Document";

  export default {
    name: "DocumentCreate",
    components: {
      "document-type-picker": () => import("../components/document-type-picker"),
      AppPageFooter: () => import("@/layouts/app-page-footer")
    },
    data: () => ({
      loading: false,
      document: new Document(),
      onFileSend: () => {}
    }),
    methods: {
      leavePage() {
        this.$router.push({ name: "documents" });
      },
      validateAndSave() {
        this.$refs.form
          .validate()
          .then(valid => {
            if (valid) {
              this.loading = true;
              this.onFileSend().then(uploaded => {
                if (uploaded) {
                  this.$api.documentService
                    .save(this.document)
                    .then(({ data }) => {
                      if (!data.error) {
                        this.$emit("success:save");
                        this.leavePage();
                      }
                    })
                    .catch(e => {
                      this.$helpers.showNotification(e.message);
                    })
                    .finally(() => {
                      this.loading = false;
                    });
                }
              });
            }
          })
          .catch(error => {
            this.loading = false;
          });
      }
    }
  };
</script>

<style scoped></style>
